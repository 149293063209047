<template>
  <div class="leftTable" style="width: 1200px; margin-top: 20px; margin-left: 65px">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      size="small"
      class="demo-ruleForm"
    >
      <el-form-item :label="$t('UserManage.user.ToLeadInto.document')">
        <el-input
          :disabled="true"
          size="small"
          v-model="ruleForm.filesName"
          style="width: 215px"
        ></el-input>
        <el-upload
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
          class="el-button mar-class-8"
          style="padding: 0; margin-left: 12px; border: none"
          :on-change="uploadChange"
          multiple
          accept=".xls,.xlsx"
        >
          <!-- <el-button size="small" type="primary"
                            >上传报告</el-button
                          > -->
          <el-button
            size="small"
            style="position: relative; background: #e5981e; border: none"
          >
            <span style="cursor: pointer; margin-top: 5px; position: absolute; top: 1px">
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{
              $t("UserManage.user.ToLeadInto.browse")
            }}</span>
          </el-button>
        </el-upload>
        <el-progress
          v-if="flagStatus"
          :percentage="percentage"
          :color="customColorMethod"
          style="width: 230px"
        ></el-progress>
      </el-form-item>
      <el-form-item>
        <el-button @click="downModel" size="small" type="primary"
          >{{ $t("AccessConfiguration.ServiceResourceManagement.DownTemplate") }}
        </el-button>
      </el-form-item>
      <el-form-item
        :label="$t('AccessConfiguration.ServiceResourceManagement.documentList')"
      >
        <el-table
          size="small"
          fit
          ref="multipleTable"
          tooltip-effect="dark"
          class="sort_table"
          :data="usertableData"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          stripe
        >
          <el-table-column
            :show-overflow-tooltip="true"
            prop="code"
            :label="$t('UserManage.user.ToLeadInto.AllowEntry')"
          >
            <template v-slot="{ row }">
              <template v-if="row.code == -1"
                ><span style="color: #ff0000">{{
                  $t("UserManage.user.ToLeadInto.isNot")
                }}</span>
              </template>
              <template v-if="row.code == 0">
                <span style="color: #008000">
                  {{ $t("UserManage.user.ToLeadInto.Is") }}</span
                >
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="resName"
            :label="$t('public.ResourceName')"
          >
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="protocolType"
            :label="$t('AccessConfiguration.ServiceResourceManagement.ProtocolType')"
          >
            <template v-slot="{ row }">
              <template v-if="row.protocolType == 1"> TCP </template>
              <template v-if="row.protocolType == 2"> UDP </template>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="resType"
            :label="$t('AccessConfiguration.ServiceResourceManagement.ResourceAddress')"
          >
            <template v-slot="{ row }">
              <template v-if="row.resType == 1"> 域名 </template>
              <template v-if="row.resType == 2"> IP </template>
              <template v-if="row.resType == 3"> IP段 </template>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="resIp"
            :label="$t('AccessConfiguration.ServiceResourceManagement.DomainName')"
          >
            <template v-slot="{ row }">
              <template v-if="row.resType == 1">
                {{ row.resAddress }}
              </template>
              <template v-else>
                {{ row.resIp }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="virtualIp"
            :label="$t('AccessConfiguration.ServiceResourceManagement.VirtualAddress')"
          >
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="resAddress"
            :label="$t('AccessConfiguration.ServiceResourceManagement.RealAddress')"
          >
            <template v-slot="{ row }">
              <template
                v-if="row.resType == 2 || row.resType == 3 || row.resType == null"
              >
                {{ row.resAddress }}
              </template>
              <template v-else>
                {{ row.resIp }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="resPort"
            :label="$t('public.Port')"
          >
            <template v-slot="{ row }">
              <template v-if="row.resPort == 0"> </template>
              <template v-else>
                {{ row.resPort }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="message"
            :label="$t('public.remark')"
            width="220"
          >
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-button v-show="btnSaveFlag" type="primary" @click="submitUpload()">{{
          $t("public.Save")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { log } from "console";
const Base64 = require("js-base64").Base64;
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      undefined;
      if (value === "") {
        undefined;

        callback(new Error("请输入手机号"));
      } else {
        undefined;

        if (!/^[0-9]*$/.test(value)) {
          undefined;

          callback(new Error("电话必须是正确的手机（电话）号码"));
        } else {
          undefined;

          callback();
        }
      }
    };
    let validcodeName = (rule, value, callback) => {
      // ^\a-\z\A-\Z0-9\u4E00-\u9FA5
      // ^<>.,?;:'()!~%-_@#%/*""\s]+$"不允许输入特殊符号和字符
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
      if (!reg.test(value)) {
        callback(new Error("不支持空格及特殊字符"));
      } else {
        callback();
      }
    };
    return {
      flagStatus: false,
      percentage: 0,
      imgUrl: vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1),
      usertableData: [], //导入列表
      btnSaveFlag: false, //导入按钮默认为空
      filename: "", //文件名
      // 导入
      form: {
        filename: "",
      },
      ruleForm: {
        userRole: "",
        filesName: "",
      },
      rules: {
        loginName: [
          { required: true, message: "请输入用户名", trigger: "blur" },

          {
            pattern: /^[0-9a-zA-Z_]{1,}$/,
            message: "用户名只支持英文（大小写）、数字或下划线组成",
            trigger: "blur",
          },
          {
            min: 4,
            max: 32,
            message: "长度在 4 到 32 个字符",
            trigger: "blur",
          },
        ],
        userName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { validator: validcodeName, trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号" },
          { validator: validatePhone, trigger: "blur" },
          {
            min: 1,
            max: 12,
            message: "最长输入12个字符，仅支持数字",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    handleSuccess(response, file, fileList) {
      this.$refs.form.clearFiles();
      this.$notify({
        title: this.$t("UserManage.user.ToLeadInto.UploadSuccessfully"),
        type: "success",
        duration: 2500,
      });
    },
    beforeUpload(file) {
      let isLt2M = true;
      isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.loading = false;
        this.$message.error(this.$t("UserManage.user.ToLeadInto.UploadFileSize"));
      }
      this.filename = file.name;
      return isLt2M;
    },
    // 监听上传失败
    handleError(e, file, fileList) {
      const msg = JSON.parse(e.message);
      this.$notify({
        title: msg.message,
        type: "error",
        duration: 2500,
      });
      this.loading = false;
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    //  新增 上传
    async uploadChange(file, fileList) {
      this.percentage = 0;
      this.flagStatus = true;
      // 获取上传的文件
      var files = file;
      this.filesName = files.name;
      this.$nextTick(() => {
        // this.ruleForm.appName = ;
        this.$set(this.ruleForm, "filesName", this.filesName);
      });
      // 判断上传的文件是否是 以apk结尾的apk文件
      if (
        files.name.substr(files.name.length - 3) === "xls" ||
        files.name.substr(files.name.length - 4) === "xlsx"
      ) {
        let formData = new FormData();
        var name = file.raw["name"];
        // .substring(0, name.lastIndexOf("."));
        this.form["filename"] = name;
        formData.append("file", file.raw);
        formData.append("fileId", "file");
        const res = await this.$axios.upload(
          "/httpServe/sdp/resource/extractFile",
          formData,
          this.updateProgress,
          false
        );
        console.log(res, "导入导入");
        if(res.data.code == 200){
          this.flagStatus = false;
          this.$message({
            message: this.$t("public.ImportSuccessful"),
            type: 'success',
            duration: 1000
          })
          this.flagStatus = false;
        }
        if (res.data.code == 101) {
          this.$message({
            message: this.$t("AccessConfiguration.ServiceResourceManagement.message"),
            type: "error",
            offset: 100,
          });
          this.flagStatus = false;
        } else {
          if (res.data) {
            this.usertableData = res.data.data.list;
            for (let i = 0; i < this.usertableData.length; i++) {
              console.log(this.usertableData[i].code, "coede");
              if (this.usertableData[i].code == 0) {
                //一个为0就让保存按钮显示，否则隐藏（-1）
                this.btnSaveFlag = true;
                return;
              } else {
                this.btnSaveFlag = false;
              }
            }
          }
        }
      } else {
        this.$nextTick(() => {
          // this.ruleForm.appName = ;
          this.$set(this.ruleForm, "filesName", "");
        });

        this.btnSaveFlag = false;
        this.usertableData = "";

        this.$message({
          message: this.$t("UserManage.user.ToLeadInto.PleaseXlsOrXlsxFile"),
          type: "error",
          offset: 100,
        });
        this.flagStatus = false;
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
      this.percentage = parseInt((e.loaded / e.total) * 100);
    },
    // 下载模板
    async downModel() {
      var params = {
        // type: 'protected',
        path: "public/模板文档/资源模板.xls",
      };
      //  /httpServe/sysFile/fileDown
      const res = await this.$axios.post("/httpServe/sysFile/fileDown", params, true);
      let url = Base64.decode(res.data);
      url = this.imgUrl + url;
      location.href = url;
    },
    // 保存上传
    async submitUpload() {
      let arr = [];
      this.usertableData.forEach((item) => {
        if (item.code == 0) {
          arr.push({
            resName: item.resName,
            protocolType: item.protocolType,
            resType: item.resType,
            resIp: item.resIp,
            virtualIp: item.virtualIp,
            resAddress: item.resAddress,
            resPort: item.resPort,
            code: item.code,
            message: item.message,
          });
        }
      });
      var param = {
        importInfo: arr,
      };
      const res = await this.$axios.post(
        "/httpServe/sdp/resource/importSDP",
        param,
        true
      );
      if (res.data == 0) {
        this.usertableData = "";
        this.visibaelFlag = true;
        this.$emit("theImport");
      }
    },
  },
};
</script>

<style></style>