<template>
  <div>
    <!-- 新增/编辑资源 -->
    <el-form
      :model="saveData"
      :rules="rules"
      ref="saveData"
      label-width="150px"
      class="demo-ruleForm"
      style="margin-top: 20px; margin-left: 3%"
    >
      <el-form-item :label="$t('public.ResourceName')+'：'" prop="resName">
        <el-input
          size="small"
          v-model="saveData.resName"
          :placeholder="$t('AccessConfiguration.ServiceResourceManagement.Placeholder')"
          style="width: 250px; margin-right: 10px"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('AccessConfiguration.ServiceResourceManagement.ProtocolType')+'：'" prop="agTypeValue">
        <!-- <div> -->
        <el-select size="small" v-model="saveData.agTypeValue" :placeholder="$t('AccessConfiguration.ServiceResourceManagement.PleaseSelect')" style="width: 250px; margin-right: 10px">
          <el-option
            v-for="item in agreementOptions"
            :key="item.type"
            :label="item.label"
            :value="item.type" >
          </el-option>
        </el-select>
      <!-- </div> -->
      </el-form-item>
      <el-form-item :label="$t('AccessConfiguration.ServiceResourceManagement.ResourceAddress')+'：'" :required="true">
          <el-select
            v-model="saveData.resType"
            :placeholder="$t('public.DomainName')"
            size="small"
            @change="changeName"
            style="width: 250px; margin-right: 10px"
          >
            <el-option
              v-for="item in devNameOptions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item :label="$t('public.DomainName')+'：'" prop="resAddress"   v-if="saveData.resType == $t('public.DomainName')">
          <el-input
            size="small"
            v-model="saveData.resAddress"
            :placeholder="$t('AccessConfiguration.ServiceResourceManagement.DomainPlaceholder')"
            style="width: 250px; margin-right: 10px"
          ></el-input>
        </el-form-item> 
        <el-form-item :label="$t('AccessConfiguration.ServiceResourceManagement.VirtualAddress')+'：'" prop="virtualAddress"  v-if="saveData.resType == 'IP' || saveData.resType == $t('public.DomainName')">
          <el-input
            size="small"
            v-model="saveData.virtualAddress"
            :placeholder="$t('AccessConfiguration.ServiceResourceManagement.VirtualPlaceholder')"
            style="width: 250px; margin-right: 10px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="真实地址：" prop="resAddress"  v-if="saveData.resType == 'IP' ">
          <el-input
            size="small"
            maxlength="100"
            v-model="saveData.resAddress"
            placeholder="请输入真实IP地址"
            style="width: 250px; margin-right: 10px" >
          </el-input>
        </el-form-item> -->
        <!-- 当输入的是IP地址时，展示要输入的IP地址范围    v-if="saveData.resType == 'IP' || saveData.resType == '域名'  || saveData.resType == 'IP段'"-->
        <el-form-item :label="$t('AccessConfiguration.ServiceResourceManagement.RealAddress')+'：'" prop="resIp">
          <el-input
            size="small"
            maxlength="100"
            v-model="saveData.resIp"
            :placeholder="$t('AccessConfiguration.ServiceResourceManagement.PleaseEnter')+`${placeholderResIp}`"
            style="width: 250px; margin-right: 10px"
          >
          </el-input>
          <span v-if="saveData.resType == $t('AccessConfiguration.ServiceResourceManagement.IPSection')">~&nbsp;&nbsp;</span>
          <el-input
            size="small"
            v-if="saveData.resType == $t('AccessConfiguration.ServiceResourceManagement.IPSection')"
            maxlength="100"
            v-model="saveData.resAddressScope"
            :placeholder="$t('AccessConfiguration.ServiceResourceManagement.IPPlaceholder')"
            style="width: 250px; margin-right: 10px"
          >
          </el-input>
        </el-form-item>
      <el-form-item :label="$t('public.Port')+'：'" prop="resPort">
        <el-input
          size="small"
          v-model="saveData.resPort"
          :placeholder="$t('AccessConfiguration.ServiceResourceManagement.ResPortPlaceholder')"
          style="width: 250px; margin-right: 10px"
          maxlength="5"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="Save('saveData')">{{$t('public.Save')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    // 特殊符号
    let validcodeName = (rule, value, callback) => {
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('ContentManage.DocumentManagement.NotSupported')));
      } else {
        callback();
      }
    };
    // 验证正整数
    const blurText = (rule, value, callback) => {
      const boolean = new RegExp("^[1-9][0-9]*$").test(value);
      if (!boolean) {
        callback(new Error(this.$t('AccessConfiguration.ServiceResourceManagement.OnlyPositiveInteger')));
      } else if (value > 65535 || value < 1) {
        callback(new Error(this.$t('AccessConfiguration.ServiceResourceManagement.NumericRange')));
      } else {
        callback();
      }
    };
    // 下拉选的正则校验
    var effectTimeChk = (rule, value, callback) => {
      if (this.saveData.resType == 1 || this.saveData.resType == this.$t('public.DomainName')) {
        let nameRegex = /^(http(s)?:\/\/)?(www\.)?([0-9a-z-]{1,}.)?[0-9a-z-]{2,}.([0-9a-z-]{2,}.)?[a-z]{2,}$/;
        let Subdomain = /^[a-zA-Z\d-]+(\.[a-zA-Z\d-]+){2}$/;
        console.log(nameRegex.test(value), "nameRegex.test(value)");
        console.log(1111, "域名");
        if (!nameRegex.test(value) || !Subdomain.test(value)) {
          console.log(33333, "域名等于空");
          callback(new Error(this.$t('AppManage.ThreatDefinition.DomainFraud.CheckDomain')));
        } else {
          callback();
        }
      } else if (this.saveData.resType == 2 || this.saveData.resType == "IP") {
        console.log(5555, " ip");
        let reg = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
        let regIpv6 = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
        let valdata = value.split(","); //IP
        let isCorrect = true; //IP

        if (valdata.length) {
          for (let i = 0; i < valdata.length; i++) {
            if (reg.test(valdata[i]) == false && regIpv6.test(valdata[i]) == false) {
              isCorrect = false;
            }
          }
        }
        if (!isCorrect) {
          console.log(6666, "ip正则验证");
          callback(new Error(this.$t('AppManage.ThreatDefinition.DomainFraud.CheckIP')));
        } else {
          callback();
        }
      } else if (this.saveData.resType == 3 || this.saveData.resType == this.$t('AccessConfiguration.ServiceResourceManagement.IPSection')) {
        let reg = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
        let regIpv6 = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
        let valdataIP = this.saveData.resAddressScope.split(","); //IP范围
        let isCorrectIP = true; //IP范围
        //IP范围
        if (valdataIP.length) {
          for (let i = 0; i < valdataIP.length; i++) {
            if (reg.test(valdataIP[i]) == false && regIpv6.test(valdataIP[i]) == false) {
              isCorrectIP = false;
            }
          }
        }
        if (!isCorrectIP) {
          console.log(888888, "ip范围正则验证");
          callback(new Error(this.$t('AccessConfiguration.ServiceResourceManagement.EnterCorrectIP')));
        } else {
          var start = this.saveData.resAddress.replace(/\./g, "") * 1;
          var end = this.saveData.resAddressScope.replace(/\./g, "") * 1;
          
          let Guoup = this.saveData.resAddress.split(".");
          let secondheat = this.saveData.resAddressScope.split(".");
          
          if (start > end) {
            callback(new Error(this.$t('AccessConfiguration.ServiceResourceManagement.ErrorIPScope')));
          } else {
            if (
              Guoup[0] != secondheat[0] ||
              Guoup[1] != secondheat[1] ||
              Guoup[2] != secondheat[2]
            ) {
              callback(new Error(this.$t('AccessConfiguration.ServiceResourceManagement.ErrorIPScope')));
            }
          }
          callback();
        }
      }
    };
    // 验证域名
    const validateName = (rule, value, callback) => {
      var IPtest = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
      // 域名正则
      const nameRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
      let valdata = value.split(","); //IP
      let isCorrect = true; //IP
      //IP
      if (valdata.length) {
        for (let i = 0; i < valdata.length; i++) {
          if(IPtest.test(valdata[i])== false){
              if(nameRegex.test(value)== false){
                callback(new Error(this.$t('AppManage.ThreatDefinition.DomainFraud.CheckDomain')));
                isCorrect = false
                return
              }else{
                return  callback()
              }
          }else{
            callback(new Error(this.$t('AccessConfiguration.ServiceResourceManagement.Nonsupport')));
            return false
          }
        }
      }
    };
    //真实地址自定义虚拟校验
    const validateAddress = (rule, value, callback) => {
      let IPRegex =/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/;
      console.log(this.saveData.resType,"resType")
      if(this.saveData.resType!=this.$t('AccessConfiguration.ServiceResourceManagement.IPSection')){
        let valdata = value.split("\n");
        console.log(value,"value---validateAddress")
        valdata.forEach((item) => {
          if (!IPRegex.test(item) ) {
            callback(new Error(this.$t('AppManage.ThreatDefinition.DomainFraud.CheckIP')));
            return;
          }
        });
        callback();
        return
      }
      if(this.saveData.resType==this.$t('AccessConfiguration.ServiceResourceManagement.IPSection')){
        let scopeStr=value+','+this.saveData.resAddressScope
        let indexArr=[]
        console.log(scopeStr,"scopeStr")
        let scopeArr=scopeStr.split(",")
        console.log(scopeArr,"scopeArr")
        scopeArr.forEach((item,index)=>{
          console.log(index,"index")
          if(!IPRegex.test(item)){
            callback(new Error(this.$t('AccessConfiguration.ServiceResourceManagement.IPScope')));
            return ;
          }else{
            indexArr.push(index)
            return
          }
          
        })
        if(indexArr.length==2){
          let start = this.saveData.resIp.replace(/\./g, "") * 1;
          let end = this.saveData.resAddressScope.replace(/\./g, "") * 1;
          if (start >= end) {
            callback(new Error(this.$t('AccessConfiguration.ServiceResourceManagement.ErrorIPAddress')));
          }else if (start ==0 || end==0) {
            callback(new Error(this.$t('AccessConfiguration.ServiceResourceManagement.IPScope')));
          }
          else {
            return callback();
          }
        }
        return
      }
    }
    //虚拟地址自定义校验
    function validateIP(rule, value, callback) {
      var IPRegex =/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/;
      let regIpv6 = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
      let valdata = value.split("\n");
      valdata.forEach((item) => {
        if (!IPRegex.test(item) ) {
          callback(new Error('请输入正确的IP地址,如：192.168.0.1'));
          return;
        }
      });
      callback();
    }
    return {
      placeholderResIp:this.$t('AccessConfiguration.ServiceResourceManagement.placeholderResIp'),
      placeholder: this.$t('AccessConfiguration.ServiceResourceManagement.DomainAddress'),
      seleShift: 1, // 选中下拉框转译
      ipFlag: 1,
      ipFlag: 0, //IP地址显示隐藏
      ipScopeFlag: 0, //IP地址范围显示隐藏
      saveData: {
        ids: [],
        resName: "",
        resType: this.$t('public.DomainName'),
        resAddress: "",//真实地址
        virtualAddress:'',//虚拟地址
        resIp: "",
        resPort: "",
        // resIpScope: "",//输入域名后IP地址范围
        resAddressScope: "", //IP地址范围
        agTypeValue:'1',//页面增加协议类型
        // domainName:'',//域名
        URL:'',//URL
      },
      rules: {
        resName: [
          { required: true, message: this.$t('AccessConfiguration.ServiceResourceManagement.Placeholder'), trigger: "blur" },
          { validator: validcodeName, trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: this.$t('AccessConfiguration.ServiceResourceManagement.NameLength'),
            trigger: "blur",
          },
        ],
        //真实地址
        resIp: [
          {
            required: true,
            message:this.placeholderResIp=this.$t('AccessConfiguration.ServiceResourceManagement.placeholderResIp')? this.$t('AccessConfiguration.ServiceResourceManagement.EnterRealIP'):this.$t('AccessConfiguration.ServiceResourceManagement.IPScope'),
            trigger: "blur",
          },
          { validator:validateAddress, trigger: "blur" },
        ],
        resPort: [
          { required: true, message: this.$t('AccessConfiguration.ServiceResourceManagement.ResPortPlaceholder'), trigger: "blur" },
          { validator: blurText, trigger: "blur" },
        ],
        agTypeValue:[   
          { required: true, message: this.$t('AccessConfiguration.ServiceResourceManagement.AgTypeValue'), trigger: "blur" },
        //   { validator: effectTimeChk, trigger: "blur" }, //域名
        ],
        //域名
        resAddress: [
          { required: true, message: this.$t('AccessConfiguration.ServiceResourceManagement.DomainEnter'), trigger: "blur" },
          { validator: validateName, trigger: "blur" }, //域名 saveData
        ],
        //虚拟地址
        virtualAddress:[   
          { required: true, message: this.$t('AccessConfiguration.ServiceResourceManagement.EnterVirtual'), trigger: "blur" },
          { validator: validateIP, trigger: "blur" },
        ],
      },
      devNameOptions: [
        { value: 1, label: this.$t('public.DomainName') },
        { value: 2, label: "IP" },
        { value: 3, label: this.$t('AccessConfiguration.ServiceResourceManagement.IPSection') },
      ],
      //协议类型
      agreementOptions:[
        { type: '1', label: "TCP" },
        { type: '2', label: "UDP" },
      ],
    };
  },
  created() {
    if (this.dictData.id) {
      console.log(this.dictData.resIp,"bainjishju ")
      this.saveData.ids = [this.dictData.id];
      this.saveData.resName = this.dictData.resName;//资源名称回显
      this.saveData.resType = this.dictData.resType;//资源地址下拉框回显
      this.saveData.agTypeValue = this.dictData.protocolType;//协议类型数据回显
      this.seleShift = this.saveData.resType//资源地址下拉框高亮回显
      this.devNameOptions.forEach((k) => {
        if (this.dictData.resType == k.value) {
          this.saveData.resType = k.label;
        }
      });
      this.saveData.resPort = this.dictData.resPort;//端口回显
      //资源地址为域名时：
      if (this.dictData.resType ==1) {
        this.ipFlag = 1;
        this.ipScopeFlag = 0;
        this.saveData.virtualAddress = this.dictData.virtualIp;//虚拟地址回显
        this.saveData.resIp=this.dictData.resIp;//真实地址回显
        this.saveData.resAddress = this.dictData.resAddress;
      } else if (this.dictData.resType ==2) {
        //资源地址为IP时：
        this.saveData.virtualAddress = this.dictData.virtualIp;//虚拟地址回显
        this.saveData.resIp=this.dictData.resAddress;//真实地址回显
      }else  if (this.dictData.resType ==3) {
         //资源地址为IP段时：
        var str1 = this.dictData.resAddress.split(",");
        this.saveData.resIp = str1[0];
        this.saveData.resAddressScope = str1[1];
        this.ipScopeFlag = 1;
      }
    }
  },

  mounted() {},
  methods: {
    // 校验框重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 下拉框选择转义
    changeName(item, formName) {
      if (item == this.$t('AccessConfiguration.ServiceResourceManagement.IPSection')) {
        this.placeholderResIp=this.$t('AccessConfiguration.ServiceResourceManagement.IPAddress')
        this.rules.resIp[0].message = this.$t('AccessConfiguration.ServiceResourceManagement.IPScope');
      } else if (item == "IP"){
        this.placeholderResIp=this.$t('AccessConfiguration.ServiceResourceManagement.placeholderResIp')
        this.rules.resIp[0].message = this.$t('AccessConfiguration.ServiceResourceManagement.EnterRealIP');
      } else if (item == this.$t('public.DomainName')){
        this.rules.resIp[0].message = this.$t('AccessConfiguration.ServiceResourceManagement.EnterRealIP');
      }

      this.devNameOptions.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value;
        }
      });
      //先var一个变量存一下 清完之后在单独赋值给
      var name = this.saveData.resName;
      this.$refs["saveData"].resetFields();
      this.saveData.resName = name;
    },
    Save(saveData) {
      // 编辑
      if (this.dictData.id) {
        console.log(this.$refs["saveData"])
        this.$refs["saveData"].validate(async (valid) => {
          if (valid) {
            var resAddress = "";
            var resIp = "";
            // 输入的是IP和范围时
            if (this.saveData.resType == "IP") {
              this.saveData.resAddressScope == null;
              resAddress = this.saveData.resIp;
            } else if (this.saveData.resType == this.$t('AccessConfiguration.ServiceResourceManagement.IPSection')) {
              const str1 = this.saveData.resIp + "," + this.saveData.resAddressScope;
              const str2 = this.saveData.resAddress;
              resAddress = this.saveData.resAddressScope ? str1 : str2;
            } else if (this.saveData.resType == this.$t('public.DomainName')) {
              
              // 当输入的是域名时
              this.saveData.resAddressScope == null;
              resAddress = this.saveData.resAddress;
              resIp = this.saveData.resIp;
            } else if (this.seleShift == 2) {
              resAddress = this.saveData.resAddress;
            } else {
              resAddress = this.saveData.resAddress + "," + this.saveData.resAddressScope;
            }

            if (
              this.saveData.resPort < parseInt(1) ||
              this.saveData.resPort > parseInt(65535)
            ) {
              this.$message({
                message: this.$t('AccessConfiguration.ServiceResourceManagement.PortRange'),
                type: "error",
                offset: 100,
                duration: 2000,
              });
              return false;
            }

            var parma = {
              ids: this.saveData.ids,
              resName: this.saveData.resName,//资源名称
              resType: this.seleShift,//资源地址
              resAddress: resAddress,//域名
              resIp: resIp,//真实地址
              resPort: this.saveData.resPort,//端口
              protocolType: this.saveData.agTypeValue,//协议类型
              virtualIp: this.saveData.virtualAddress,//虚拟地址
            };
            const res = await this.$axios.post(
              "/httpServe/sdp/resource/update",
              parma,
              true
            );
            // 保存失败，已存在相同名称的文件或文件夹
            this.flag = 1;
            this.$emit("addSave", res);
          } else {
            console.log("error");
          }
        });
      } else {
        // 新增
        this.$refs["saveData"].validate(async (valid) => {
           
          if (valid) {
            var resAddress = "";
            var resIp = "";
            // 输入的是IP和范围时
            if (this.saveData.resType == "IP") {
              this.saveData.resAddressScope == null;
              resAddress = this.saveData.resIp;
            } else if (this.saveData.resType == this.$t('AccessConfiguration.ServiceResourceManagement.IPSection')) {
              const str1 = this.saveData.resIp + "," + this.saveData.resAddressScope;
              const str2 = this.saveData.resIp;
              resAddress = this.saveData.resAddressScope ? str1 : str2;
            } else if (this.saveData.resType == this.$t('public.DomainName')) {
              // 当输入的是域名时
              this.saveData.resAddressScope == null;
              resAddress = this.saveData.resAddress;
              resIp = this.saveData.resIp;
            } else if (this.seleShift == 2) {
              resAddress = this.saveData.resAddress;
            } else {
              resAddress = this.saveData.resAddress + "," + this.saveData.resAddressScope;
            }
            if (
              this.saveData.resPort < parseInt(1) ||
              this.saveData.resPort > parseInt(65535)
            ) {
              this.$message({
                message: this.$t('AccessConfiguration.ServiceResourceManagement.PortRange'),
                type: "error",
                offset: 100,
                duration: 2000,
              });
              return false;
            }
            console.log(this.saveData,"新增")
            var parma = {
              ids: this.saveData.ids,
              resName: this.saveData.resName,//资源名称
              resType: this.seleShift,//资源地址
              resAddress: resAddress,//域名
              resIp: resIp,//真实地址
              resPort: this.saveData.resPort,//端口
              protocolType: this.saveData.agTypeValue,//协议类型
              virtualIp: this.saveData.virtualAddress,//虚拟地址
            };
            const res = await this.$axios.post(
              "/httpServe/sdp/resource/save",
              parma,
              true
            );
            // 保存失败，已存在相同名称的文件或文件夹
            this.flag = 1;
            this.$emit("addSave", res);
          } else {
            console.log("error");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  /* line-height: 40px; */
  position: relative;
  font-size: 14px;
  display: flex;
}
</style>