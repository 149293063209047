<template>
  <div>
    <div>
      <!-- 业务资源管理 -->
      <div class="box" v-if="flag == 1">
        <el-row :gutter="4" style="display: flex">
          <!-- <el-col :span="2">
                      <el-select style="width: 100%" size="small" v-model="searchData.searchType"
                          @keyup.enter.native="search" placeholder="请选择" @change="changeName">
                          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                      </el-select>
                  </el-col> -->
          <!-- <el-col :span="3">
                      <el-input style="width: 100%" v-model="searchData.searchContent" :placeholder="`请输入${placeholder}`" 
                          size="small" @keyup.enter.native="search">
                      </el-input>
                  </el-col>
                  <el-col :span="4">
                      <el-button type="primary" size="small" @click="search">查询</el-button>
                  </el-col> -->
          <select-input
            :options="options1"
            @selectSearchChange="selectSearchChange"
            @searchList="searchList"
          ></select-input>
        </el-row>
        <el-row :gutter="3" style="margin-bottom: 10px">
          <el-button-group>
            <!-- 新增 -->
            <el-button
              type="primary"
              @click="addResource"
              class="determineBtn"
              size="small"
              >{{
                $t("AccessConfiguration.ServiceResourceManagement.NewResource")
              }}</el-button
            >
            <!-- 导入 -->
            <el-button @click="theInport()" type="primary" size="small">{{
              $t("public.Toleadinto")
            }}</el-button>
            <!-- 导出 -->
            <el-button
              style="margin-right: 0.4px"
              @click="resetForm()"
              type="primary"
              size="small"
              >{{ $t("public.Derive") }}</el-button
            >
            <!-- 删除 -->
            <el-button
              style="margin-left: 1px"
              :type="delType"
              :disabled="delFlag"
              @click="delArr(delarr)"
              class="delbtn"
              size="small"
              >{{ $t("public.Delete") }}</el-button
            >
          </el-button-group>
        </el-row>
        <!-- 列表 -->
        <div style="position: relative">
          <el-table
            ref="tableData"
            :data="tableData"
            :row-key="getRowKeys"
            lazy
            size="mini"
            stripe
            class="tableClass"
            tooltip-effect="dark"
            style="width: 100%"
            :row-style="iRowStyle"
            :cell-style="iCellStyle"
            :header-row-style="iHeaderRowStyle"
            :header-cell-style="{
              background: '#f7f8fa',
              color: '#606266',
              padding: '0px',
            }"
            :default-sort="{ prop: 'update_time', order: 'descending' }"
            @selection-change="delobj"
            @sort-change="handleSortChange"
          >
            <el-table-column
              type="selection"
              align="center"
              width="60"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              prop="resName"
              :label="$t('public.ResourceName')"
              width="180"
              style="line-height: 30px"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              style="color: #6f7982"
              prop="protocolType"
              :label="$t('AccessConfiguration.ServiceResourceManagement.ProtocolType')"
              width="180"
              :show-overflow-tooltip="true"
            >
              <template v-slot="{ row }">
                <template v-if="row.protocolType == 1"> TCP </template>
                <template v-if="row.protocolType == 2"> UDP </template>
              </template>
            </el-table-column>
            <el-table-column
              style="color: #6f7982"
              prop="virtualIp"
              :label="$t('AccessConfiguration.ServiceResourceManagement.VirtualAddress')"
              width="180"
              :show-overflow-tooltip="true"
            >
              <template v-slot="{ row }">
                <template v-if="row.virtualIp">
                  {{ row.virtualIp }}
                </template>
                <template v-else>
                  <!-- N/A -->
                </template>
              </template>
            </el-table-column>
            <el-table-column
              style="color: #6f7982"
              prop="resIp"
              :label="$t('AccessConfiguration.ServiceResourceManagement.RealAddress')"
              width="180"
              :show-overflow-tooltip="true"
            >
              <template v-slot="{ row }">
                <template v-if="row.resType == 2 || row.resType == 3">
                  {{ row.resAddress }}
                </template>
                <template v-else>
                  {{ row.resIp }}
                </template>
              </template>
            </el-table-column>

            <el-table-column
              style="color: #6f7982"
              prop="resPort"
              :label="$t('public.Port')"
              width="80"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="update_time"
              :label="$t('public.ModificationTime')"
              sortable="custom"
              width="180"
              :show-overflow-tooltip="true"
            >
            <template v-slot="{row}">
               {{row.updateTime}}   
           </template>
            </el-table-column>
            <el-table-column :label="$t('public.Controls')">
              <template v-slot="{ row }">
                <span class="action_icon">
                  <img
                    @click="nameKuang(row)"
                    src="@/assets/icon_see.png"
                    :title="
                      $t('AccessConfiguration.ServiceResourceManagement.ResRelation')
                    "
                  />
                </span>
                <span class="action_icon">
                  <img
                    @click="edit(row)"
                    src="@/assets/icon_write.png"
                    :title="$t('public.Editor')"
                  />
                </span>
                <span class="action_icon">
                  <img
                    @click="del(row.id)"
                    src="@/assets/icon_del.png"
                    :title="$t('public.Delete')"
                  />
                </span>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <pagination
            :page="searchData.currentPage"
            :limit="searchData.pageSize"
            :total="total"
            :pageSizes="[10, 20, 30, 40]"
            v-on:handleChildGetList="handleParentGetList"
          ></pagination>
        </div>
      </div>
    </div>
    <!-- 新增 -->
    <addService v-if="flag == 2" @addSave="addSave" :dictData="dictData" />
    <div v-if="flag == 5">
      <TheImport @theImport="theImports" />
    </div>
    <!-- 关联应用的弹框 -->
    <Eldialog
      :num="'520px'"
      :title="$t('AccessConfiguration.ServiceResourceManagement.AssociatedGateway')"
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
      style="overflow: auto"
    >
      <el-input
        v-model="detailInp"
        @input="(e) => (ipt = validForbid(e))"
        @keyup.enter.native="searchDetail"
        size="small"
        style="width: 180px; margin-bottom: 10px"
        :placeholder="
          $t('AccessConfiguration.ServiceResourceManagement.dialogPlaceholder')
        "
      >
        <i
          @click="searchDetail"
          slot="suffix"
          class="el-input__icon el-icon-search"
          style="cursor: pointer"
        ></i>
      </el-input>
      <el-table
        :cell-style="{ padding: '3px 0px' }"
        :header-row-style="{ height: '30px' }"
        size="mini"
        stripe
        ref="multipleTable"
        :data="detailData"
        class="tableClass"
        tooltip-effect="dark"
        style="width: 100%"
        :default-sort="{ prop: 'updateTime', order: 'descending' }"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="gwName"
          :label="$t('AccessConfiguration.ServiceResourceManagement.GatewayName')"
          width="150"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="gwAddress"
          :label="$t('AccessConfiguration.ServiceResourceManagement.GatewayAddress')"
          width="150"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="gwPort"
          :label="$t('public.DomainName')"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="updateTime"
          :label="$t('public.ModificationTime')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
      </el-table>
      <p style="margin-top: 10px">
        {{ $t("AccessConfiguration.ServiceResourceManagement.Total")
        }}<b>{{ this.detailTotal }}</b
        >{{ $t("AccessConfiguration.ServiceResourceManagement.Records") }}
      </p>
    </Eldialog>
  </div>
</template>
<script>
import selectInput from "@/components/selectInput";
import pagination from "@/components/pagination/page";
import addService from "./components/addService.vue"; //新增编辑
import Eldialog from "@/components/elDialog"; //详情弹框
import TheImport from "./components/TheImport.vue"; //导入
export default {
  components: {
    pagination,
    addService,
    Eldialog,
    selectInput,
    TheImport, //导入
  },
  data() {
    return {
      flag: 1,
      total: 0,
      delType: "info", //删除按钮类型默认置灰
      delFlag: true, //删除按钮禁用默认
      detailTotal: 0,
      detailId: "",
      detailInp: "",
      placeholder: this.$t("public.ResourceName"),
      statusVisible: false,
      dictData: {},
      searchData: {
        currentPage: 1,
        pageSize: 10,
        searchType: "resName",
        searchContent: "",
        orderRule: "DESC",
        orderColume: "update_time",
      },
      tableData: [],
      detailData: [],
      delarr: [], //多选删除
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      options1: [
        {
          value: "resName",
          label: this.$t("public.ResourceName"),
        },
        {
          value: "resAddress",
          label: this.$t("AccessConfiguration.ServiceResourceManagement.RealAddress"),
        },
        {
          value: "virtualIp",
          label: this.$t("AccessConfiguration.ServiceResourceManagement.VirtualAddress"),
        },
        {
          value: "port",
          label: this.$t("public.Port"),
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getDataList();
  },
  methods: {
    // 导入
    theInport() {
      this.flag = 5;
    },
    // 导入
    theImports() {
      this.flag = 1;

      // this.visibaelFlag = true
      this.$message({
        message: this.$t(
          "AccessConfiguration.ServiceResourceManagement.ImportSuccessfully"
        ),
        type: "success",
        offset: 100,
      });
      this.getDataList();
    },
    // 导出
    async resetForm() {
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA";

      let userString = decodeURIComponent(escape(window.atob(token2.split(".")[1])));

      // let userString = decodeURIComponent(
      //   escape(window.atob(this.token.split(".")[1]))
      // );
      this.userId = JSON.parse(userString).userId;
      let param = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        searchType: this.selectVal ? this.selectVal.deviceName : "",
        searchContent: this.selectVal ? this.selectVal.InputText : "",
        orderRule: "DESC",
        orderColume: "updateTime",
      };
      const res = await this.$axios.postExport(
        "/httpManageExport/sdp/resource/exportExcel",
        param,
        false,
        this.userId
      );
      const link = document.createElement("a");
      var fileName = "exportSDPResource_" + Date.parse(new Date()) + ".xls";
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    selectSearchChange() {},
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 切换查询内容
    changeName(item) {
      console.log(item, "item");
      if (item == "resName") {
        this.placeholder = this.$t("public.ResourceName");
      } else if (item == "address") {
        this.placeholder = this.$t(
          "AccessConfiguration.ServiceResourceManagement.ResourceAddress"
        );
      } else {
        this.placeholder = this.$t("public.DomainName");
      }
      // this.placeholder = item;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.searchData.currentPage = page;
      this.searchData.pageSize = limit;
      // 调用查询方法
      this.getDataList(this.selectVal);
    },
    // 列表
    async getDataList(val) {
      let params = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        searchType: val ? val.deviceName : "",
        searchContent: val ? val.InputText : "",
        orderRule: this.searchData.orderRule,
        orderColume: this.searchData.orderColume,
      };
      const res = await this.$axios.post(
        "/httpServe/sdp/resource/getDataInfo",
        params,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 新增资源
    addResource() {
      this.flag = 2;
      this.dictData.id = "";
    },
    // 新增资源成功
    addSave(res) {
      if (res.code == 200) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.flag = 1;
        this.getDataList();
      } else if (res.code == 500) {
        this.$message.error({
          message: res.message,
          offset: 100,
        });
      }
    },
    // 编辑id
    edit(val) {
      console.log(val, "编辑");
      this.dictData = val;
      this.flag = 2;
    },
    // 点击名称详情
    async nameKuang(row) {
      this.detailId = row.id;
      this.statusVisible = true;
      var param = {
        resId: row.id,
        gwName: this.detailInp,
      };
      const res = await this.$axios.post(
        "/httpServe/sdp/gateway/selectGateWay",
        param,
        true
      );
      this.detailData = res.data;
      this.detailTotal = res.data.length;
    },
    // 弹框查询
    async searchDetail() {
      var param = {
        resId: this.detailId,
        gwName: this.detailInp,
      };
      const res = await this.$axios.post(
        "/httpServe/sdp/gateway/selectGateWay",
        param,
        true
      );
      this.detailData = res.data;
      this.detailTotal = res.data.length;
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false; //点击出现详情弹框
      this.detailInp = ""; //关联应用清空条件
    },
    // 高度
    heightCustom() {
      this.height.height = this.num;
    },
    // 删除
    del(id) {
      var parma = { ids: [id] };
      this.$confirm(
        this.$t("AccessConfiguration.ServiceResourceManagement.CheckDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/sdp/resource/delete",
            parma,
            true
          );
          if (res.code == 200) {
            this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: "success",
              offset: 100,
            });
          } else {
            this.$message.error({
              message: res.message,
              offset: 100,
            });
          }
          this.$refs.tableData.clearSelection();
          this.searchData.currentPage = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
    // 多选删除
    delobj(obj) {
      if (obj.length >= 1) {
        this.delarr = obj;
        this.delType = "primary";
        this.delFlag = false;
      } else {
        this.delarr = obj;
        this.delType = "info";
        this.delFlag = true;
      }
    },
    // 多选删除接口
    delArr(val) {
      var ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });
      // 请求接口
      var parma = {
        ids: ids,
      };
      this.$confirm(
        this.$t("AccessConfiguration.ServiceResourceManagement.CheckDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/sdp/resource/delete",
            parma,
            true
          );
          if (res.code == 200) {
            this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: "success",
              offset: 100,
            });
          } else {
            this.$message.error({
              message: res.message,
              offset: 100,
            });
          }
          this.$refs.tableData.clearSelection();
          this.searchData.currentPage = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
    // 查询
    async searchList(val) {
      this.selectVal = val;
      this.searchData.currentPage = 1;
      let params = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        orderColume: this.searchData.orderColume,
        orderRule: this.searchData.orderRule,
        searchType: val.deviceName,
        searchContent: val.InputText,
      };
      const res = await this.$axios.post(
        "/httpServe/sdp/resource/getDataInfo",
        params,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    //排序
    handleSortChange(column) {
      if (column.order === "ascending") {
        this.searchData.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.searchData.orderRule = "DESC";
      }
      this.searchData.orderColume = column.prop;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 10px;
}

::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
